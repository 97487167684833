button {
  outline: none;
  border: none;
  background: none;
}

/* placeholder */
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #D6D6D6;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #D6D6D6;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #D6D6D6;
}

/* scroll bar */
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #D6D6D6;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  border-radius: 20px;
}

body.modal-open {
  overflow: hidden;
}

/* antd modal buttons */
.ant-modal-root .ant-modal-content {
  border-radius: 4px;
}
.ant-modal-content .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
  float: none;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  background: #E19668;
  border: 1px #E19668 solid;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in, color 0.2s ease-out;
  padding: .25rem 24px;
  height: 30px;
  font-weight: 500;
  font-size: 18px;
  font-family: sans-serif;
  color: #ffffff;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover {
  background: #DD7535;
  border: 1px #DD7535 solid;
  color: #ffffff;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn.ant-btn-primary[disabled] {
  background: #D6D6D6;
  border: 1px #D6D6D6 solid;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
  background: #ffffff;
  border: 1px #808080 solid;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in, color 0.2s ease-out;
  padding: .25rem 24px;
  height: 30px;
  font-weight: 500;
  font-size: 18px;
  font-family: sans-serif;
  color: #4D4D4D;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn:hover {
  background: #ffffff;
  border: 1px #000000 solid;
  color: #000000;
}

/* table footer for last update */
.ant-table-footer {
  border: none!important;
  background: transparent!important;
  position: absolute!important;
}

/* logout popover */
.ant-popover-inner-content {
  padding: 0!important;
}

.ant-table-thead tr th {
  z-index: 10;
}
.ant-table-thead tr th:last-child {
  z-index: 9;
}

/* Dropdown with border */
.ant-dropdown-menu.border {
  border: 1px solid #808080;
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
}
/* status dropdown */
.ant-dropdown-menu.borderBottom .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-dropdown-menu.borderBottom .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  padding: 8px 2px;
  margin: 0px 12px;
  cursor: default;
}
.ant-dropdown-menu.borderBottom .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:not(:last-child) {
  border-bottom: 1px solid #EDEDED;
}


.ant-checkbox-inner {
  border-radius: 0;
  border-color: #394864;
  border-width: 2px;
  background-color: transparent;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #FFFFFF;
}
.ant-checkbox:not(.ant-checkbox-indeterminate) .ant-checkbox-inner::after{
  top: 44%;
  left: 16%;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #394864;
}
.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #394864;
  border-color: #394864!important;
}
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover::after,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox:active .ant-checkbox-inner,
.ant-checkbox:focus .ant-checkbox-inner,
.ant-checkbox:focus-within .ant-checkbox-inner,
.ant-checkbox:visited .ant-checkbox-inner {
  border-color: #394864;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #D6D6D6;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #394864 !important;
}

/* collapse */
.ant-collapse.ant-collapse-icon-position-left.site-collapse-custom-collapse {
  background: #fff;
  border: 1px solid #EDEDED;
  border-radius: 10px;
}
.ant-collapse.ant-collapse-icon-position-left.site-collapse-custom-collapse .ant-collapse-item {
  overflow: hidden;
  background: #fff;
  border: 0px;
  border-radius: 10px;
}
.ant-collapse.ant-collapse-icon-position-left.site-collapse-custom-collapse > .ant-collapse-item > .ant-collapse-header {
  background: #fff;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
  font-size: 16px;
}
.ant-collapse.ant-collapse-icon-position-left.site-collapse-custom-collapse > .ant-collapse-item > .ant-collapse-content {
  border-top: none;
}

.ant-picker-ok .ant-btn-sm {
  background: #E19668;
  border-color: #E19668;
}
.ant-picker-ok .ant-btn-sm:hover {
  background: #DD7535;
  border-color: #DD7535;
  color: #ffffff;
}

.site-collapse-custom-collapse .ant-collapse-content.ant-collapse-content-active {
  border-top: 0;
}
.site-collapse-custom-collapse .ant-collapse-header {
  background-color: #fff;
}


/* select */

.countryCode > div {
  border: 1px solid #808080 !important;
  height: 30px !important;
  border-radius: 5px !important;
}

/* Tabs */
.ant-tabs-nav {
  width: fit-content!important;
}
.ant-tabs-nav-wrap .ant-tabs-nav-list > div:nth-last-child(2) {
  margin-right: 2px!important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #E19668!important;
}
.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: #E19668!important;
}
.ant-tabs-tab:hover {
  color: #E19668!important;
}
.ant-tabs-ink-bar {
  background: #E19668!important;
}

/* tooltip */
.ant-tooltip-inner {
  background-color: #889ABA!important;
  color: #fff;
  border-radius: 2px;
}
.ant-tooltip-arrow > span {
  background-color: #889ABA!important;
}
